import React, { useState } from "react";
import "./App.css";
import Error  from "./error.png"

function App() {
  const [showDetails, setShowDetails] = useState(false);

  function retry() {
    window.location.reload();
  }

  function toggleDetails() {
    setShowDetails(!showDetails);
  }

  return (
    <div className="App">
      <div className="container">
        <img src={Error} alt="Fehler Bild" className="error-image" />
        <h1>Die Website ist nicht erreichbar</h1>
        <p>
          Entschuldigung, die von Ihnen angeforderte Webseite ist derzeit nicht
          verfügbar.
        </p>
        <p>Versuche Folgendes:</p>

        <ul>
          <li>
            Schließe den Webbrowser und öffne Instagram.
          </li>
          <li>
            Gehe auf das Profil:{" "}
            <a className="custom-underline" href="https://www.instagram.com/moon.lounge0711">
              Moon Lounge 0711
            </a>
          </li>
          <li>
            Folge uns auf Instagram, um immer auf dem neuesten Stand zu sein.
          </li>
        </ul>
        <span>ERR_CONNECTION_REFUSED</span>
        <div className="button-group">
          <button className="btn" onClick={retry}>Neu laden</button>
          <button className="btn1" onClick={toggleDetails}>Details</button>
        </div>
        {showDetails && (
          <div className="details">
            <h2>Details:</h2>
            <p>
              Diese Fehlerseite wurde angezeigt, weil die angeforderte Webseite
              nicht erreichbar ist. Dies kann verschiedene Gründe haben:
            </p>
            <ul>
              <li>Die Webseite ist vorübergehend offline.</li>
              <li>Es gibt ein Problem mit Ihrer Internetverbindung.</li>
              <li>Die URL ist möglicherweise falsch eingegeben.</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
